<template>
    <div class="chart-view">
        <div class="l-padded chart-view__datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="l-stack l-gap-2 chart-view__chart">
            <AssetChartsSettings
                :axes="settingsAxes"
                @change="handleSettingsChange"
            />

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <template v-else>
                <AssetChartsStats
                    :colors="chartOptions.colors"
                    :series="series"
                    :units="units"
                />

                <ApexChart
                    height="360px"
                    :options="chartOptionsComputed"
                    :series="series"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { evaluate } from 'mathjs'
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, languageHelper } from '@/utils'
import AssetChartsSettings from '@/components/AssetChartsSettings'
import AssetChartsStats from '@/components/AssetChartsStats'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

export default {
    name: 'AssetCustomMeasurementsChartView',
    components: {
        ApexChart,
        AssetChartsSettings,
        AssetChartsStats,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        assetType: {
            type: String,
            default: null,
        },
        id: {
            type: [String, Number],
            required: true,
        },
        measurement: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'line',
                    animations: {
                        enabled: false,
                    },
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: true,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
            series: [{ data: [] }],
            yAxisBounds: [],
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes']),
        ...mapGetters('tracker', ['assetTypesById', 'assetsById']),
        assetTypeChartOptions() {
            const items = this.assetTypesById[
                this.assetsById[this.id]?.asset_details.asset_type
            ]?.measurements?.items?.[this.measurement]

            return items?.chart?.map(options => {
                if (options.main) {
                    options.measurements = items.measurements
                    options.props = {
                        ...items.props,
                        ...options.props,
                    }
                }
                options.name =
                    options.props?.label?.[this.$i18n.locale] ||
                    options.props?.label?.[languageHelper.defaultLanguage] ||
                    (options.measurements.length === 1
                        ? options.measurements[0]
                        : '')
                return options
            })
        },
        chartOptionsComputed() {
            return {
                ...this.chartOptions,
                yaxis: this.yAxis.map(axis => {
                    const boundsIndex = this.settingsAxes.findIndex(
                        title => title === (axis.title?.text || axis.seriesName)
                    )
                    return {
                        ...axis,
                        ...(this.yAxisBounds[boundsIndex] && {
                            min: this.yAxisBounds[boundsIndex][0],
                            max: this.yAxisBounds[boundsIndex][1],
                        }),
                    }
                }),
            }
        },
        settingsAxes() {
            return this.yAxis
                .filter(axis => axis.show !== false)
                .map(axis => axis.title?.text || axis.seriesName)
        },
        units() {
            return this.assetTypeChartOptions.map(
                options => options.props?.unit || ''
            )
        },
        yAxis() {
            if (!this.assetTypeChartOptions) {
                return {}
            }

            let defaultAxisIndex
            let defaultAxisTitles = []
            let visibleAxesCount = 0

            const axes = this.assetTypeChartOptions.reduce((acc, cur, i) => {
                if (
                    cur.main ||
                    (cur.props && ('min' in cur.props || 'max' in cur.props))
                ) {
                    const title =
                        cur.name && cur.props?.unit
                            ? `${cur.name} (${cur.props.unit})`
                            : cur.name
                    if (cur.main) {
                        defaultAxisIndex = i
                        if (title) {
                            defaultAxisTitles.push(title)
                        }
                    }
                    acc.push({
                        seriesName: cur.name,
                        decimalsInFloat: 2,
                        min: cur.props?.min,
                        max: cur.props?.max,
                        opposite: !!visibleAxesCount++,
                        ...(title && { title: { text: title } }),
                    })
                } else {
                    const title =
                        cur.name && cur.props?.unit
                            ? `${cur.name} (${cur.props.unit})`
                            : cur.name
                    if (title) {
                        defaultAxisTitles.push(title)
                    }
                    acc.push({
                        show: false,
                    })
                }
                return acc
            }, [])

            if (defaultAxisIndex === undefined) {
                defaultAxisIndex = axes.findIndex(axis => axis.show === false)
                if (defaultAxisIndex === -1) {
                    defaultAxisIndex = 0
                }
            }

            if (axes[defaultAxisIndex]) {
                if (!axes[defaultAxisIndex].seriesName) {
                    axes[defaultAxisIndex].seriesName = this.series[
                        defaultAxisIndex
                    ].name
                    axes[defaultAxisIndex].show = true
                }
                const seriesName = axes[defaultAxisIndex].seriesName
                const text = defaultAxisTitles.join(` ${this.$t('and')} `)
                axes[defaultAxisIndex].title = { text }
                return axes.map(axis =>
                    axis.show === false ? { ...axis, seriesName } : axis
                )
            }

            return axes
        },
    },
    watch: {
        assetTypeChartOptions: {
            immediate: true,
            handler() {
                if (!this.assetTypeChartOptions) {
                    this.$router.push({
                        name: 'detail',
                        params: {
                            id: this.id,
                        },
                    })
                }
            },
        },
        customRange() {
            this.load()
        },
        measurement() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        handleSettingsChange(...args) {
            this.yAxisBounds = (Array.isArray(args[0]) ? args[0] : [args]).map(
                ([mode, min, max]) => {
                    switch (mode) {
                        case 'auto':
                        case 'custom':
                            return [min, max]
                    }
                }
            )
        },
        async load() {
            if (!this.assetTypeChartOptions) {
                return
            }
            this.isLoading = true
            const results = await this.loadData()
            this.setData(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&timestamp_min=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }
            return results
        },
        setData(results) {
            this.series = this.assetTypeChartOptions.map(options => ({
                name: options.name,
                data: results
                    .filter(item =>
                        options.measurements.every(measurement =>
                            Object.prototype.hasOwnProperty.call(
                                item.sensor_data,
                                measurement
                            )
                        )
                    )
                    .map(item => [
                        item.timestamp,
                        options.props?.converter
                            ? evaluate(
                                  options.props.converter,
                                  options.measurements.reduce(
                                      (acc, key) => ({
                                          ...acc,
                                          [key]: item.sensor_data[key],
                                      }),
                                      {}
                                  )
                              )
                            : options.measurements.length === 1
                            ? item.sensor_data[options.measurements[0]]
                            : undefined,
                    ]),
            }))
        },
    },
}
</script>

<i18n>
{
    "en": {
        "and": "and"
    },
    "de": {
        "and": "und"
    },
    "fr": {
        "and": "et"
    },
    "it": {
        "and": "e"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-view {
    display: flex;

    &__chart {
        flex-grow: 100;
        padding: 1rem;
        border-left: $style-border;
        overflow-x: hidden;
    }

    &__datepicker {
        width: 20%;
        min-width: 350px;
    }

    @include respond-to('for-tablet-down') {
        display: block;

        &__chart {
            border: none;
        }

        &__datepicker {
            padding: 1rem;
            width: auto;
            min-width: auto;
            border-bottom: $style-border;
        }
    }
}
</style>
